var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":true,"color":_vm.color,"timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbar_msg != null)?_c('span',[_vm._v(_vm._s(_vm.snackbar_msg))]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('br'),_c('v-card',{staticClass:"card"},[_c('v-card-title',{staticClass:"heading justify-center"},[_vm._v("Add Periodical Report")]),_c('div',{},[_c('div',{staticClass:"add-section"},[_c('div',{staticClass:"d-flex"},[_c('v-col',[_c('label',{staticClass:"add-text"},[_vm._v("Periodic Series ")]),_c('v-autocomplete',{staticClass:"text",attrs:{"placeholder":"Select Series","items":_vm.series_data,"item-text":"name","item-value":"id","outlined":"","dense":"","rules":[_vm.rules.required],"clearable":"","required":""},model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}})],1),_c('v-col',[_c('label',[_vm._v("Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"text",attrs:{"placeholder":"Select Start Date","append-icon":"event","persistent-hint":"","dense":"","readonly":"","outlined":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',[_c('label',[_vm._v("End Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"text",attrs:{"placeholder":"Select End Date","append-icon":"event","persistent-hint":"","dense":"","readonly":"","outlined":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.fromDateMenu1),callback:function ($$v) {_vm.fromDateMenu1=$$v},expression:"fromDateMenu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.fromDateMenu1 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('div',[_c('center',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"success"},on:{"click":_vm.dailyData1}},[_vm._v("Fetch Data")])],1)],1)]),_c('v-data-table',{staticClass:"elevation-5 ma-2",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.tabledata},scopedSlots:_vm._u([{key:"item.date_of_purchase",fn:function(ref){
var item = ref.item;
return [(item.date_of_purchase != '')?_c('div',[_vm._v(" "+_vm._s(item.date_of_purchase.split("T")[0].split("-")[2]+"-"+item.date_of_purchase.split("T")[0].split("-")[1]+"-"+item.date_of_purchase.split("T")[0].split("-")[0])+" ")]):_vm._e()]}},{key:"item.subscription_from",fn:function(ref){
var item = ref.item;
return [(item.subscription_from != '')?_c('div',[_vm._v(" "+_vm._s(item.subscription_from.split("T")[0].split("-")[2]+"-"+item.subscription_from.split("T")[0].split("-")[1]+"-"+item.subscription_from.split("T")[0].split("-")[0])+" ")]):_vm._e()]}},{key:"item.subscription_to",fn:function(ref){
var item = ref.item;
return [(item.subscription_to != '')?_c('div',[_vm._v(" "+_vm._s(item.subscription_to.split("T")[0].split("-")[2]+"-"+item.subscription_to.split("T")[0].split("-")[1]+"-"+item.subscription_to.split("T")[0].split("-")[0])+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white","align":"center","justify":"center"}},[_c('v-toolbar-title',[_vm._v("Add Periodical Report")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tabledata.length != 0)?_c('download-excel',{staticClass:"ma-3",attrs:{"color":"primary","dark":"","data":_vm.tabledata,"fields":_vm.fields,"worksheet":"My Worksheet","name":"periodical_report.xls"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")])],1)],1):_vm._e()],1)]},proxy:true}])})],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }